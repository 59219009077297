// import $ from 'jquery';
import STPFood from './stpfood.class';

"use strict";

if ($.fn.preferabliSTPFood === undefined) {

    var _stp,
        _isJQ = !!(window.jQuery),
        _window = $(window),
        _document = $(document);

    function PreferabliSTPFood(element, options) {

        this._stp;
        this._defaultOptions = {
            lang: 'en'
        };

        $.extend({}, this);
        this._name = 'preferabliSTP';
        this.element = element;

        if (!((element instanceof jQuery && element instanceof Object) || (element instanceof Element) || (typeof element === 'string' && document.querySelector(element)))) {
			throw 'You must have an element to enable Preferabli Similar Tasting Products plugin.';
		}

		if (typeof options.integration_id === 'undefined') {
			throw 'Intergration ID is required to access Preferabli Similar Tasting Products functionality. Please contact Preferabli to obtain your integration id.';
		}

		if (typeof options.collection_id === 'undefined') {
			throw 'Collection ID is required for Preferabli Similar Tasting Products functionality. Please contact Preferabli to obtain your collection id.';
		}

        this.opts = $.extend({}, this._defaultOptions, Object.assign(options, {
            root: element,
        }));

        this.init();

    };


    PreferabliSTPFood.prototype = {
        constructor: PreferabliSTPFood,
        init: function() {
        	let _el;

			if(this.opts.root instanceof jQuery && this.opts.root instanceof Object) _el = this.opts.root[0]; // jQuery El
			if(this.opts.root instanceof Element && this.opts.root.nodeType === 1) _el = this.opts.root; // VJs El

            this._stp = new STPFood(_el, {...this.opts, element: _el});
            
            window.preferabliSTPFood = this._stp;

        },
        getProducts: function(params) {
			this._stp.getLTTT(params);
		}	
    };

    $.fn.preferabliSTPFood = function(options) {

        if (typeof options === "string") {
            return this.each(() => {
                var jqEl = $(this);
                var instance = jqEl.data('preferabliSTPFood');
                return (instance && instance[options]) ? instance[options].apply(instance, Array.prototype.slice.call(arguments, 1)) : this;
            });

        } else {
            return this.each(() => {

                var jqEl = $(this);
                options = $.extend(true, {}, options);

                if (_isJQ) {
                    jqEl.data('preferabliSTPFood', new PreferabliSTPFood(this, options));
                } else {
                    jqEl[0].preferabliSTPFood = new PreferabliSTPFood(this, options);
                }

                return this;
            });
        }
    };

    $.fn.preferabliSTPFood = $.fn.preferabliSTPFood;

}