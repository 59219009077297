import clsx                                from 'clsx';

import PropTypes                           from 'prop-types';
import React, { Suspense, lazy, useState, useEffect } from 'react'
import logoPaths from './paths';



const PoweredByLogo = ({
  logoNumber=4,
  logoType='logomark',
  ...props
}) => {
  const [iconNotFound, setIsNotFound] = useState(false);
  const [logo, setLogo] = useState();

  useEffect(() => {
    if(logoNumber && logoNumber !== null){
      const logo = Object.values(logoPaths).find((optLogo) => {
        return (optLogo.id === Number(logoNumber));
      });
      setLogo(logo);
    }
  }, [logoNumber]);

  const { ...svgProps } = props;

  const DynamicLoader = (params) => {
    const LazyComponent = params.path;
    if (!LazyComponent) {
      console.error('No SVG was found that match your query.', props.icon);
      setIsNotFound(true)
      return null
    }
    return (
      <Suspense fallback={ <g /> }>
        <LazyComponent { ...svgProps }/>
      </Suspense>
    )
  }

  if (iconNotFound) {
    return null
  }

  return (
    <>
    {logo && (
    <svg
      viewBox={`0 0 ${logo.viewBox}`}
      className={ clsx(
        'stpf-js__poweredby',
        logo.type === 'wordmark' && 'stpf-js__poweredby--wordmark',
        logo.type === 'logomark' && 'stpf-js__poweredby--logomark',
        props.className,
        ) }
    >
      <DynamicLoader {...logo} />
    </svg>
    )}
    </>
  );

};


PoweredByLogo.propTypes = {
  logoNumber: PropTypes.number,
  logoType: PropTypes.string,
}


export default React.memo(PoweredByLogo)

