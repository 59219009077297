import React, { useEffect, useState } from 'react';
import EventEmitter from 'UTILS/emitter';


export const STPFoodContext = React.createContext({});
STPFoodContext.name = 'STPFoodContext';

export const STPFoodProvider = ({ children, parameters }) => {

  const getStyles = (params) => {
    return '';
  }

  const hasDecimalPlaces = () => {
    if (!showDecimalPlaces) return 0;
    if (showDecimalPlaces && numDecimalPlaces) return Number(numDecimalPlaces);
    return 2;
  };

  const context = {
    getStyles,
    hasDecimalPlaces,
  };

  return <STPFoodContext.Provider value={ context }>{children}</STPFoodContext.Provider>;
};